'use client'

import { useWindowScroll } from 'react-use'
import { Icons } from './ui/icons'
import { cn } from '@/utils'
import { useCallback, useLayoutEffect, useState } from 'react'

export const ScrollDown = () => {
  const { y } = useWindowScroll()
  const [scrollLimit, setScrollLimit] = useState(0)

  useLayoutEffect(() => {
    setScrollLimit(
      Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      ) - window.innerHeight
    )
  }, [])

  const onScrollDown = useCallback(() => {
    const section1 = document.querySelector('#section-1')
    const section2 = document.querySelector('#section-2')
    const section3 = document.querySelector('#section-3')
    const section4 = document.querySelector('#section-4')

    if (section1 && section1.clientHeight > y) {
      section1.scrollIntoView({ behavior: 'smooth' })
    } else if (section2 && section2.clientHeight > y) {
      section2.scrollIntoView({ behavior: 'smooth' })
    } else if (section3 && section3.clientHeight > y) {
      section3.scrollIntoView({ behavior: 'smooth' })
    } else if (section4 && section4.clientHeight > y) {
      section4.scrollIntoView({ behavior: 'smooth' })
    } else {
      window.scrollTo({ top: scrollLimit, behavior: 'smooth' })
    }
  }, [y, scrollLimit])

  return (
    <button
      type='button'
      className={cn(
        'flex flex-col items-center gap-1 text-sm text-gray-400 cursor-pointer animate-bounce rounded-xl p-2 z-50',
        y > scrollLimit - 300 && 'hidden'
      )}
      onClick={onScrollDown}
    >
      <p className='text-lg text-white'>Scroll Down</p>
      <Icons.chevronDown
        className='text-brand-main animate-rotate-y animate-infinite'
        size={28}
      />
    </button>
  )
}
