'use client'

import { useTranslation } from '@/app/i18n/client'
import { cn } from '@/utils'
import Image from 'next/image'
import { FC } from 'react'
import image1 from '../../public/landing/boring-rewards.png'
import image2 from '../../public/landing/stake-and-earn.png'
import { Button } from './ui/button'
import Link from 'next/link'

type StepItem = {
  index: number
  title: string
  description: string
  image: string
  ctaTitle: string
  ctaLink: string
  reverse?: boolean
}

type StepIndexProps = {
  index: number
  className?: string
}

const StepIndex: FC<StepIndexProps> = ({ index, className }) => {
  return (
    <div
      className={cn(
        'relative w-[54px] h-[54px] mt-6 hidden md:grid place-items-center col-start-2 z-10',
        'rounded-full border border-gray-500 bg-black shadow-step',
        className
      )}
    >
      <p className='text-3xl font-semibold text-gray-400'>{index}</p>
    </div>
  )
}

const Step: FC<StepItem> = ({
  title,
  description,
  image,
  index,
  ctaLink,
  ctaTitle,
  reverse
}) => {
  return (
    <div
      className={cn(
        'flex flex-col-reverse gap-6 grid-flow-dense md:grid max-md:text-center'
      )}
      style={{ gridTemplateColumns: '1fr 54px 1fr' }}
    >
      <div className={cn('md:mx-4', reverse && 'col-start-3')}>
        <Image
          src={image}
          alt={title}
          width={450}
          height={215}
          className='w-full'
        />
      </div>

      <StepIndex index={index + 1} />

      <div
        data-testid='section-2'
        className={cn('flex-1 mt-6', reverse && 'col-start-1')}
      >
        <h3 className='text-3xl font-semibold'>{title}</h3>
        <p className='text-gray-400 mt-2 mb-5'>{description}</p>

        <Button data-testid='landing-page-button' variant='outline' asChild>
          <Link href={ctaLink}>{ctaTitle}</Link>
        </Button>
      </div>
    </div>
  )
}

const StepsConf = [
  { image: image1.src, ctaLink: '' },
  { image: image2.src, ctaLink: '/staking' }
]

export const Steps = () => {
  const { t } = useTranslation('landing')

  return (
    <div className='flex flex-col gap-6 md:gap-28'>
      <div className='hidden md:flex justify-center h-[168px]'>
        <StepIndex index={1} className='mt-0' />
      </div>

      {([1, 2] as const).map((n, index) => {
        return (
          <Step
            key={n}
            index={n}
            reverse={n % 2 === 0}
            title={t(`section3.steps.${n}.title`)}
            description={t(`section3.steps.${n}.subtitle`)}
            image={StepsConf[index].image}
            ctaTitle={t(`section3.steps.${n}.cta`)}
            ctaLink={StepsConf[index].ctaLink}
          />
        )
      })}
    </div>
  )
}
